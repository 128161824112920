import { markerItems } from "../../../constants/markers";

// For Star Rating
const numbersArray = [1, 2, 3, 4, 5];

const createProductElement = (htmlString) => {
  const wrapper = document.createElement("div");
  wrapper.innerHTML = htmlString.trim();

  return wrapper.firstChild;
};

const renderProductItem = ({
  id,
  title,
  amount,
  in_stock,
  absolute_url,
  image,
  image_url,
  rounded_stars,
  price,
  discount_price,
  currency,
  markers = [],
  items_in_favours = [],
}) => {
  const isDiscountProduct = discount_price !== price;
  const isFavoriteProduct = items_in_favours.includes(id);

  const { availability } = in_stock || {};

  const markerElements = markers
    .map(
      (marker) =>
        `<span class="card_hint sub_title sub_title_1 color_white 
      ${marker.name === markerItems.discounts ? "sale" : "new"}"
       >
        ${marker.name}
       </span>
      `
    )
    .join("");

  const starRatingElements = numbersArray
    .map(
      (number) =>
        `<input ${number === rounded_stars ? "checked" : ""} 
        type="radio"
        name="star" class="star-${number}">
        <label class="star-${number}" for="star-${number}">${number}</label>`
    )
    .join("");

  const titleWordsArray = title.split(" ");
  const truncatedWordsArray =
    titleWordsArray.length > 10
      ? titleWordsArray.slice(0, 10)
      : titleWordsArray;

  const truncatedTitle = truncatedWordsArray.join(" ");

  const imageElement = image ? `<img src="${image_url}" alt="${title}">` : ``;

  // const imageElement = image
  //   ? `<img data-src="${image_url}" class="swiper-lazy" loading="lazy" crossFade="true" alt="${title}">
  //      <div class="swiper-lazy-preloader swiper-lazy-preloader-black"></div>`
  //   : ``;

  const priceElements = isDiscountProduct
    ? `
    <div class="card_old_price bold_title bold_title_0 color_black">
        <span class="card_old_price_value card_old_price_line">${price}</span>
        <span class="card_old_price_currency">${currency.symbol}</span>
    </div>
    <div class="card_new_price bold_title bold_title_2 color_red">
        <span class="card_new_price_value">${discount_price}</span>
        <span class="card_new_price_currency">${currency.symbol}</span>
    </div>
    `
    : `
    <div class="card_new_price bold_title bold_title_2 color_black">
        <span class="card_new_price_value">${price}</span>
        <span class="card_new_price_currency">${currency.symbol}</span>
    </div>
    `;

  const availabilityElement = availability
    ? `
    <div class="product_card_btn card_btn btn_standart btn_standart_green bold_title bold_title_2 color_white modal_open"
     data-href="basket_modal_wrapper">
        КУПИТИ
    </div>`
    : `
    <div class="btn_not-found modal_open" data-href="basket_modal_wrapper">
        Немає в наявності
    </div>
    `;

  return createProductElement(
    `
    <div data-quantity_item_id="${id}" data-item_amount="${amount}"
    class="card_item product_card__block swiper-slide 
    ${!availability ? "not-available" : ""}">

    <div class="card_hint__block">
        ${markerElements}
    </div>

    <a href="${absolute_url}" class="card_img">
        ${imageElement}  
    </a>

    <div class="card_info">
        <div class="card_rating sub_title sub_title_1 color_black">
            <div class="comment_star">
                <form class="form_stars blocked">
                    <span class="stars">
                        ${starRatingElements}
                        <span></span>
                    </span>
                </form>
            </div>
        </div>
    </div>
    <a href="${absolute_url}" class="card_title middle_title middle_title_1 color_black">
        ${truncatedTitle}
    </a>
    <div class="card-hover"></div>
    <div class="card_price__block">
        ${priceElements}
    </div>

    <div class="card_hidden__block">
        <div class="card_btn__block">
            ${availabilityElement}

            <div class="card_like card_like ${isFavoriteProduct ? "liked" : ""}"
                data-id="${id}">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.4779 9.06889L15.4781 9.06866L15.4818 9.06318L15.5014 9.03466C15.5197 9.00831 15.5483 8.96769 15.5867 8.91483C15.6635 8.80902 15.7792 8.65472 15.9295 8.46814C16.2311 8.09365 16.6668 7.59609 17.2029 7.10125C18.3054 6.0835 19.6907 5.20829 21.125 5.20829C24.1834 5.20829 26.625 7.64988 26.625 10.7083C26.625 14.7385 23.6909 18.5876 20.5291 21.531C18.9732 22.9794 17.4134 24.1612 16.241 24.9814C15.6557 25.391 15.1689 25.709 14.8301 25.9236C14.8037 25.9404 14.7781 25.9565 14.7536 25.9719C14.7284 25.9559 14.7022 25.9392 14.6751 25.9218C14.336 25.7043 13.8489 25.3824 13.2632 24.9686C12.0901 24.1398 10.5295 22.9479 8.97268 21.4931C5.80674 18.5347 2.875 14.6909 2.875 10.7262C2.875 7.64827 5.31819 5.20829 8.37501 5.20829C9.80936 5.20829 11.1946 6.0835 12.2971 7.10125C12.8332 7.59609 13.2689 8.09365 13.5706 8.46814C13.7209 8.65472 13.8365 8.80902 13.9133 8.91483C13.9517 8.96769 13.9803 9.00831 13.9986 9.03466L14.0183 9.06318L14.022 9.06866L14.0221 9.06889L14.0222 9.06902L14.0224 9.06929L14.0226 9.06953L14.75 10.1607L15.4775 9.06953L15.4776 9.06929L15.4778 9.06902L15.4779 9.06889Z"
                        stroke="black" stroke-width="1.75" class="card_heart" />
                    <path
                        d="M18 10.5C18.6667 9.66666 20.4 8.09999 22 8.49999C24 8.99999 23.5 11 23.5 12"
                        stroke="#73CAC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        class="card_heart_2" />
                </svg>
            </div>
        </div>
    </div>
        </div>
        `
  );
};

export const renderProducts = (data, productsClassName) => {
  const productsBlock = document.querySelector(productsClassName);

  data?.results?.forEach((product) =>
    productsBlock.appendChild(renderProductItem({ ...data, ...product }))
  );
};
