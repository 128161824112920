import './index.scss';
// import './search/index.scss';
import "./search/index";
import './basket_modal/index'

window.onload = function () {
    const data_link = document.querySelectorAll('[data-link]')

    data_link.forEach(href => href.addEventListener('click', () => {
        window.location = href.dataset.link
    }))


    let delivery_hint_close = document.getElementsByClassName('delivery_hint_close')[0];
    delivery_hint_close.addEventListener("click", hide_hint);

    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });

    setTimeout(() => {
        document.querySelector('.bottom_navigation').style.display = 'flex'
        if (document.querySelector('.bread_crumbs_section')) {
            document.querySelector('.bread_crumbs_section').style.display = 'block'
        }
        // document.querySelector('.bottom_navigation').style.opacity = '1'
    }, 0)
    

    function hide_hint() {
        let hint_delivery__block = document.getElementsByClassName('delivery_hint__block')[0];
        let header_nav = document.getElementsByClassName('header_navigation')[0];
        hint_delivery__block.classList.add('hidden');
        header_nav.classList.remove('header_navigation_delivery');
        let bottom_navigation = document.getElementsByClassName('bottom_navigation')[0];
        bottom_navigation.classList.remove('delivery');
    }

    // {
    //     let navigation_phone_arrow = document.getElementsByClassName('navigation_phone_arrow')[0];
    //     navigation_phone_arrow.addEventListener("click", open_phones);

    //     function open_phones() {
    //         let navigation_another_phone = document.getElementsByClassName('navigation_another_phone')[0];
    //         navigation_another_phone.classList.toggle('active');
    //     }
    // }

    let header_navigation = document.getElementsByClassName('header_navigation')[0];
    let menu_btn = document.getElementsByClassName('menu_btn')[0];
    menu_btn.addEventListener("click", menu_action);

    function menu_action() {
        let header_navigation = document.getElementsByClassName('header_navigation')[0];
        let menu_btn = document.getElementsByClassName('menu_btn')[0];
        menu_btn.classList.toggle('active');
        header_navigation.classList.toggle('active');
        document.body.classList.toggle('prevent_scrolling')
    }

    // window.addEventListener('scroll', function (e) {
    //     let scrollTop = getBodyScrollTop();
    //     if (scrollTop >= 350) {
    //         header_navigation.classList.add('scroll');
    //         header_navigation.classList.add('fixed');
    //         hide_hint();
    //     } else if (scrollTop >= 220 && scrollTop <= 350) {
    //         header_navigation.classList.add('animation');
    //         header_navigation.classList.remove('active');
    //         menu_btn.classList.remove('active');
    //     } else {
    //         document.querySelector('.navigation_search').classList.remove('open_search');
    //         header_navigation.classList.remove('scroll');
    //         header_navigation.classList.remove('animation');
    //         header_navigation.classList.remove('fixed');

    //     }
    // });

    function getBodyScrollTop() {
        return self.pageYOffset || (document.documentElement && document.documentElement.scrollTop) || (document.body && document.body.scrollTop);
    }





    let location__block = document.getElementsByClassName('location__block')[0];
    let mobile__block = document.getElementsByClassName('mobile__block')[0];
    let location_triger = document.querySelectorAll('.location_triger');
    let mobile_triger = document.querySelectorAll('.mobile_triger');
    let mini_modal_close = document.querySelectorAll('.mini_modal_close');

    location_triger.forEach(item => {
        item.addEventListener("click", function () {
            mobile__block.classList.remove('active');
            location__block.classList.toggle('active');
        });
    });
    mobile_triger.forEach(item => {
        item.addEventListener("click", function () {
            location__block.classList.remove('active');
            mobile__block.classList.toggle('active');
        });
    });

    mini_modal_close.forEach(item => {
        item.addEventListener("click", function () {
            // this.target.classList.remove('active');
            this.closest(".mini_modal").classList.remove('active');
        });
    });



    // document.onclick = function (e) {
    //     let check_location_triger = event.target.classList.contains('location_triger');
    //     let check_mobile_triger = event.target.classList.contains('mobile_triger');
    //     let check_mobile_active = mobile__block.classList.contains('active');
    //     let check_location_active = location__block.classList.contains('active');

    //     if (check_location_triger == false && check_location_active == true) {
    //         location__block.classList.remove('active');
    //     }

    //     if (check_mobile_triger == false && check_mobile_active == true) {
    //         mobile__block.classList.remove('active');
    //     }
    // };




    let icon_search = document.querySelector('.navigation_icon_search')
    icon_search.addEventListener('click', function () {
        document.querySelector('.navigation_search').classList.toggle('open_search');
    });



    {
        let header_content_btn = document.querySelectorAll('.bottom_navigation_content_btn');
        header_content_btn.forEach(element => {
            element.addEventListener("click", function () {
                let container = element.closest('.bottom_navigation_content_item');
                container.classList.toggle('active');
            });
        });

        let width = document.documentElement.clientWidth;
        if (width <= 992) {
            // let navigation_name = document.querySelectorAll('.bottom_navigation_name');
            // navigation_name.forEach(element => {
            //     element.addEventListener("click", function (e) {
            //         // mobile_navigation_content_btn
            //         var target = e.target || e.srcElement;
            //         console.log('target: ', target);
            //         e.preventDefault();

            //     });
            // });


            let mobile_navigation_content_btn = document.querySelectorAll('.mobile_navigation_content_btn');

            mobile_navigation_content_btn.forEach(item => {
                item.addEventListener("click", function (e) {
                    this.closest(".bottom_navigation_link").classList.toggle('active');
                });
            });


        }
    }



};
