function nova_pochta() {
  let select_city, select_department;

  create_city_select({
    city_name: ".select_city",
    select_noResult: "Міст не знайдено",
    api: "/api/settlements/",
  });

  create_department_select({
    city_name: ".select_city",
    department_name: ".select_department",
    select_noResult: "Відділень не знайдено",
    api: "/api/warehouses/",
  });

  function create_city_select({ city_name, select_noResult, api }) {
    select_city = new TomSelect(".select_city", {
      create: false,
      placeholder: "Виберіть місто",
      maxOptions: 100,
      valueField: "value",
      labelField: "title",
      searchField: "title",
      sortField: {
        field: "text",
        direction: "asc",
      },
      searchField: ["value", "type"],
      onFocus() {
        select_city.setValue("");
      },
      onItemAdd() {
        select_city.blur();
      },
      // fetch remote data
      load: function (query, callback) {
        let data = {
          per_page: 20,
          // title: params.term,
          // page_number: params.page || 1,
        };

        let url =
          query != ""
            ? `/api/settlements/?title=${encodeURIComponent(query)}`
            : `/api/settlements/`;
        fetch(url, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((json) => {
            let length = json.results.length;
            select_city.clear();
            select_city.clearOptions();
            if (length >= 1) {
              json.results.forEach((element) => {
                select_city.addOption({
                  value: `${element.title}`,
                  title: `${element.title}`,
                });
              });
            }
            callback();
          })
          .catch(() => {
            callback();
          });
      },
    });

    fetch(api, {
      method: "GET",
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        data.results.forEach((element) => {
          select_city.addOption({
            value: element.title,
            title: `${element.title} (${element.region.title})`,
          });
        });
        select_city.setValue([data.results[0]?.title]);
      });

    return select_city;
  }

  function create_department_select({
    city_name,
    department_name,
    select_noResult,
    api,
  }) {
    let per_page;
    let active_city;
    let first_search = true;

    select_department = new TomSelect(".select_department", {
      create: false,
      placeholder: "Виберіть відділення",
      maxOptions: 100,
      valueField: "value",
      labelField: "title",
      searchField: "title",
      sortField: {
        field: "text",
        direction: "asc",
      },
      // load: (query, callback) => {
      //     console.log('select_department load...', this)
      // },
      onFocus() {
        select_department.setValue("");
      },
      onItemAdd() {
        select_department.blur();
      },
      render: {
        no_results: function (data, escape) {
          if (first_search && per_page !== 0) {
            first_search = false;

            fetch(`${api}?query=${active_city}&per_page=${per_page}`, {
              method: "GET",
            })
              .then((data) => {
                return data.json();
              })
              .then((data) => {
                // select_department.clear();
                // select_department.clearOptions();

                data.results.forEach((element) => {
                  select_department.addOption({
                    value: `${element.title}`,
                    title: `${element.title}`,
                  });
                });
                select_department.refreshOptions();
              });
          } else {
            return '<div class="no-results">Цього відділення не існує або в обраному місті немає відділень нової почти</div>';
          }
        },
      },
    });
    select_city.on("change", function (item) {
      if (item != "") {
        console.log("item:::::::: ", item);
        active_city = item;
        first_search = true;

        fetch(`${api}?query=${item}`, {
          method: "GET",
        })
          .then((data) => {
            return data.json();
          })
          .then((data) => {
            let length = data.results.length;
            console.log("length: ", length);
            select_department.clear();
            select_department.clearOptions();
            per_page = data.count;

            if (length >= 1) {
              let first_value = data.results[0].title;

              data.results.forEach((element) => {
                select_department.addOption({
                  value: `${element.title}`,
                  title: `${element.title}`,
                });
              });
              select_department.setValue([first_value]);
            } else {
            }
          });
      }
    });

    document
      .querySelector("#tomselect-2-ts-dropdown")
      .addEventListener("scroll", () => {
        const select = document.querySelector("#tomselect-2-ts-dropdown");
        // const height = select.offsetHeight
        const scroll = select.scrollHeight;
        const scrolled = select.scrollTop;

        let length = select.querySelectorAll(".option").length;
        let max_option = select_department.settings.maxOptions;
        if (scrolled + 500 >= scroll && length == max_option) {
          select_department.settings.maxOptions =
            select_department.settings.maxOptions + 100;
          select_department.refreshOptions();
        }
      });
  }
}

export { nova_pochta };
