export const addToCartGT = ({
  id,
  name,
  price,
  brand,
  items,
  quantity = 1,
  currency = "UAH",
}) => {
  window.dataLayer = window.dataLayer || [];

  dataLayer.push({
    event: "add_to_cart",
    currency,
    items,
    add: {
      products: [
        {
          id,
          name,
          price,
          brand,
          quantity,
        },
      ],
    },
  });
};

export const removeFromCartGT = ({
  id,
  name,
  price,
  brand,
  items,
  quantity = 1,
  currency = "UAH",
}) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "remove_from_cart",
    value: '',
    currency,
    items,
    ecommerce: {
      currency,
      remove: {
        products: [
          {
            id,
            name,
            price,
            brand,
            quantity,
          },
        ],
      },
    },
  });
};

export const beginCheckoutGT = ({
  totalPrice,
  items,
  currency = "UAH"
}) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "begin_checkout",
    gtm: {
      uniqueEventId: "2",
      start: 1710749625317,
    },
    ecommerce: {
      value: totalPrice,
      currency,
      items,
    },
  });
};

export const purchaseGT = ({
  transaction_id,
  totalPrice,
  items,
  currency = "UAH",
}) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: "purchase",
    value: totalPrice,
    currency,
    items,
    transaction_id,
  });
};
