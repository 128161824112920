import "./index.scss";

import { renderProducts } from "../../module/renderProducts";
import { swiperProducts } from "../../module/catalog_elements";
import { getMarkerProducts } from "../../../api/products";
import { markerItems } from "../../../constants/markers";

console.log(getMarkerProducts);

let swiper = new Swiper(".main_slider__block", {
  lazy: true,
  speed: 1000,
  autoplay: {
    delay: 4000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
});

let sponsor_swiper = new Swiper(".sponsor_swiper", {
  speed: 1000,
  // slidesPerView: 'auto',
  spaceBetween: 30,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    1500: {
      slidesPerView: 8,
    },
    1200: {
      slidesPerView: 7,
    },
    992: {
      slidesPerView: 6,
    },
    600: {
      slidesPerView: 5,
    },
    500: {
      slidesPerView: 4,
    },
    200: {
      slidesPerView: 3,
    },
  },
});

let width = document.documentElement.clientWidth;
if (width <= 1350) {
  let swiper = new Swiper(".best_sales_swiper", {
    speed: 1000,
    slidesPerView: 1,
    spaceBetween: 30,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
      },
      300: {
        slidesPerView: 1,
      },
    },
  });
}

const main_slider__block = document.querySelector(".main_slider__block");

if (main_slider__block) {
  main_slider__block.addEventListener("click", ({ target }) => {
    const slide = target.closest(".swiper-slide");

    if (slide) {
      const slideLink = slide.dataset.slideLink;

      window.location.href = slideLink;
    }
  });
}

// product sections
if (Array.isArray(swiperProducts)) {
  let hitsProductsData = null;
  let noveltiesProducts = null;
  let discountProducts = null;

  const hitsProductsClassName = "hits-products";
  const noveltiesProductsClassName = "novelties-products";
  const discountProductsClassName = "discount-products";

  const hitsSwiperProducts = swiperProducts.find((item) =>
    item.wrapperEl.classList.contains(hitsProductsClassName)
  );

  const noveltiesSwiperProducts = swiperProducts.find((item) =>
    item.wrapperEl.classList.contains(noveltiesProductsClassName)
  );

  const discountSwiperProducts = swiperProducts.find((item) =>
    item.wrapperEl.classList.contains(discountProductsClassName)
  );

  const getHitsProducts = async () => {
    if (hitsSwiperProducts) {
      const data = await getMarkerProducts(
        markerItems.hits,
        hitsProductsData?.next
      );
      hitsProductsData = data;

      renderProducts(data, `.${hitsProductsClassName}`);
      hitsSwiperProducts.updateSlides();
    }
  };

  const getNoveltiesProducts = async () => {
    if (noveltiesSwiperProducts) {
      const data = await getMarkerProducts(
        markerItems.novelties,
        noveltiesProducts?.next
      );
      noveltiesProducts = data;

      renderProducts(data, `.${noveltiesProductsClassName}`);
      noveltiesSwiperProducts.updateSlides();
    }
  };

  const getDiscountProducts = async () => {
    if (discountSwiperProducts) {
      const data = await getMarkerProducts(
        markerItems.discounts,
        discountProducts?.next
      );
      discountProducts = data;

      renderProducts(data, `.${discountProductsClassName}`);
      discountSwiperProducts.updateSlides();
    }
  };

  getHitsProducts();
  getNoveltiesProducts();
  getDiscountProducts();

  if (hitsSwiperProducts) {
    hitsSwiperProducts.on("reachEnd", () => {
      getHitsProducts();
    });
  }

  if (noveltiesSwiperProducts) {
    noveltiesSwiperProducts.on("reachEnd", () => {
      getNoveltiesProducts();
    });
  }

  if (discountSwiperProducts) {
    discountSwiperProducts.on("reachEnd", () => {
      getDiscountProducts();
    });
  }
}
