import { instance } from "./instance";

export async function getProducts(marker, url) {
  try {
    const { data } = await instance.get(
      url || `/api/items${marker ? "/?markers=" + marker : ""}`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function getMarkerProducts(marker, url) {
  try {
    const { data } = await instance.get(
      url || `/api/markers${marker ? "/?markers=" + marker : ""}`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
}
