import './index.scss';
// import validation from '../validation/index';

// let subscribe_btn = document.querySelectorAll('.subscribe_btn');

// if (subscribe_btn.length >= 1) {
//     subscribe_btn[0].addEventListener('click', function () {
//         let status = validation(subscribe_btn[0]);

//         if (status == true) {
//             console.log('send!');
//         } else {
//             console.log('error!');
//         }

//     });
// }
