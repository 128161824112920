import "./star_rate.scss";
import "./index.scss";
import {
  minus,
  plus,
  input_basket,
  delete_item,
  create_city_select,
  create_department_select,
  change_counter,
} from "../shop_scripts/index";
import {
  addToCartGT,
  removeFromCartGT,
  beginCheckoutGT,
} from "../googleTagEvents";
import { bad_modal } from "../../common_componentc/form_action";

let checker = document.querySelectorAll(".card_swiper").length;

export const swiperProducts = checker
  ? new Swiper(".card_swiper", {
      lazy: true,
      slidesPerView: 1,
      spaceBetween: 30,
      speed: 1000,
      // autoplay: {
      //   delay: 2500,
      //   disableOnInteraction: false,
      // },
      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true,
      // },
      breakpoints: {
        // 1600: {
        //     slidesPerView: 6,
        // },
        1400: {
          slidesPerView: 6,
        },
        1100: {
          slidesPerView: 5,
        },
        700: {
          slidesPerView: 4,
        },
        500: {
          slidesPerView: 3,
        },
        300: {
          slidesPerView: 2,
          spaceBetween: 0,
        },
      },
    })
  : {};

// if (checker >= 1) {
// }

if (checker < 1 && swiperProducts?.length) {
  swiperProducts.map((item) => item.disable());
}

basket_render();

let all_card_btn = document.querySelectorAll(".product_card_btn");
let delete_cards = document.querySelector(".basket_total_clear_btn");
let card_like = document.querySelectorAll(".card_like");
let item_like = document.querySelector(".item_like");
let card_like_delete = document.querySelectorAll(".card_delete");
let all_delete_btn = document.querySelectorAll(".basket_item_delete");
let all_count_plus = document.querySelectorAll(".count_plus");
let all_count_minus = document.querySelectorAll(".count_minus");
let all_count_input = document.querySelectorAll(".count_input");

if (card_like_delete != null) {
  card_like_delete.forEach((like_btn_delete) => {
    like_btn_delete.addEventListener("click", function () {
      let item_id = this.closest(".product_card__block").dataset
        .quantity_item_id;

      fetch(`/api/favour_item/${item_id}/remove_by_like/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((body) => {
        delete_item(this, ".product_card__block");
        const header_likes_counter = document.querySelector(
          ".header-likes_counter"
        );
        header_likes_counter.textContent =
          +header_likes_counter.textContent - 1;
      });
    });
  });
}

// if (item_like != null) {
//     item_like.addEventListener("click", function () {
//         let item_id = this.closest(".product_card__block").dataset.quantity_item_id;
//         var arr_api = {
//             item_id: Number(item_id),
//         };

//         document.querySelector(".navigation_icon_like").style.transform = "scale(1.3)";
//         setTimeout(() => {
//             document.querySelector(".navigation_icon_like").style.transform = "scale(1)";
//         }, 200);

//         fetch(`/api/favour_items/`, {
//             method: "POST",
//             body: JSON.stringify(arr_api),
//             headers: {
//                 "Content-Type": "application/json",
//                 Accept: "application/json",
//             },
//         })
//         .then((data) => {
//             if (data.ok) {
//                 this.classList.add('liked')
//             }
//         })
//     });
// }

// if (card_like != null) {
//     card_like.forEach((like_btn) => {
//         like_btn.addEventListener("click", function () {
//             if (!like_btn.classList.contains('liked')) {
//                 let item_id = this.closest(".product_card__block").dataset.quantity_item_id;
//                 console.log("this: ", this);
//                 console.log("item_id: ", item_id);
//                 var arr_api = {
//                     item_id: Number(item_id),
//                 };

//                 document.querySelector(".header-likes_counter").style.transform = "scale(1.3)";
//                 setTimeout(() => {
//                     document.querySelector(".header-likes_counter").style.transform = "scale(1)";
//                 }, 200);

//                 fetch(`/api/favour_items/`, {
//                         method: "POST",
//                         body: JSON.stringify(arr_api),
//                         headers: {
//                             "Content-Type": "application/json",
//                             Accept: "application/json",
//                         },
//                     })
//                     .then((data) => {
//                         if (data.ok) {
//                             const all_item_id = document.querySelectorAll(`[data-quantity_item_id="${item_id}"]`)
//                             const header_likes_counter = document.querySelector(".header-likes_counter")
//                             all_item_id.forEach(item => item.querySelector('.card_like').classList.add('liked'))
//                             header_likes_counter.textContent = +header_likes_counter.textContent + 1
//                         }
//                     })
//             }
//         });
//     });
// }

if (delete_cards != null) {
  delete_cards.addEventListener("click", function () {
    fetch(`/api/cart_items/`, {
      method: "DELETE",
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        let all_cards = document.querySelectorAll(".product_card__block");
        all_cards.forEach((card) => {
          let pseudo_btn = card.querySelector(".basket_item_delete");
          delete_item(pseudo_btn, ".product_card__block");
        });

        change_counter(data.cart_items_quantity, null, ".header_counter");
        change_counter(data.cart_total_price, null, ".header_price");

        let length_item = $(".product_card__block_all_value").length;
        let current_price = Number(
          wrapper.querySelector(".product_card__block_all_value").textContent
        );
        let all_price;
        if (length_item == 1) {
          all_price = 0;
        } else {
          all_price = return_all_price() - current_price;
        }

        document.querySelector(
          ".product_card_total_price"
        ).textContent = `${parseInt(all_price)}`;
      });
  });
}

if (all_delete_btn != null) {
  all_delete_btn.forEach((element) => {
    element.addEventListener("click", delete_btn);
  });
}

all_count_plus.forEach((count) => {
  count.addEventListener("click", basket_plus);
});

all_count_minus.forEach((count) => {
  count.addEventListener("click", basket_minus);
});

all_count_input.forEach((count) => {
  count.addEventListener("change", basket_input);
});

// all_card_btn.forEach((element) => {
//     element.addEventListener("click", function () {
//         debugger
//         let input = this.closest(".product_card__block").querySelector(".count_input");
//         let quantity = input != null ? Number(input.value) : 1;
//         let item_id = Number(this.closest(".product_card__block").dataset.quantity_item_id);
//         let body = {
//             item_id: item_id,
//             quantity: quantity,
//         };
//         const item_amount = this.closest(".product_card__block").dataset.item_amount

//         if (item_amount == 0) {
//             return false
//         } else if (document.querySelector(`.basket_product_container > [data-id="${item_id}"]`)) {
//             const basket_item = document.querySelector(`.basket_product_container > [data-id="${item_id}"]`)
//             const basket_input_value = +basket_item.querySelector('.count_input').value
//             const amount = +basket_item.querySelector(".count_input").dataset.item_amount

//             if (quantity + basket_input_value > amount) {
//                 const avaliable = amount - basket_input_value
//                 bad_modal('В кошик добавлена максимальна кількість товару, який є в наявності')
//                 if (avaliable > 0) {
//                     body.quantity = avaliable
//                 } else {
//                     return false
//                 }
//             }
//         }

//         fetch("/api/cart_items/", {
//                 method: "POST",
//                 body: JSON.stringify(body),
//                 headers: {
//                     "Content-Type": "application/json",
//                     Accept: "application/json",
//                 },
//             })
//             .then((data) => {
//                 return data.json();
//             })
//             .then((data) => {
//                 change_counter(data.cart_items_quantity, null, ".header_counter");
//                 // change_counter(data.cart_total_price, null, ".header_price");
//                 basket_render()
//                 document.querySelector('.basket_modal_wrapper').classList.add('active')
//                 document.querySelector('.overlay').classList.add('active')
//             });
//     });
// });

document.body.addEventListener("click", function (e) {
  const target = e.target;

  const isAddFavourBtn =
    target.classList.contains("card_like") ||
    (target.tagName == "svg" && target.closest(".card_like"));
  const isCardNotFavour =
    isAddFavourBtn &&
    !target.classList.contains("liked") &&
    !target.closest(".liked");
  const isCardFavour =
    isAddFavourBtn &&
    (target.classList.contains("liked") || target.closest(".liked"));

  if (target.classList.contains("product_card_btn")) {
    let input = target
      .closest(".product_card__block")
      .querySelector(".count_input");
    let quantity = input != null ? Number(input.value) : 1;
    let item_id = Number(
      target.closest(".product_card__block").dataset.quantity_item_id
    );
    let body = {
      item_id: item_id,
      quantity: quantity,
    };
    const item_amount = target.closest(".product_card__block").dataset
      .item_amount;

    if (item_amount == 0) {
      return false;
    } else if (
      document.querySelector(
        `.basket_product_container > [data-id="${item_id}"]`
      )
    ) {
      const basket_item = document.querySelector(
        `.basket_product_container > [data-id="${item_id}"]`
      );
      const basket_input_value =
        +basket_item.querySelector(".count_input").value;
      const amount =
        +basket_item.querySelector(".count_input").dataset.item_amount;

      if (quantity + basket_input_value > amount) {
        const avaliable = amount - basket_input_value;
        bad_modal(
          "В кошик добавлена максимальна кількість товару, який є в наявності"
        );
        if (avaliable > 0) {
          body.quantity = avaliable;
        } else {
          return false;
        }
      }
    }

    fetch("/api/cart_items/", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        const addedItem = data?.cart_items.find(
          (item) => item.item.id === body.item_id
        );

        const preparedItems = data.cart_items.map(({ id, item, quantity }) => {
          const { title, brand, price } = item;
    
          return {
            id: id,
            name: title,
            brand: brand,
            price: price,
            quantity: quantity,
          };
        });

        addToCartGT({
          id: body.item_id,
          name: addedItem.item.title,
          brand: addedItem.item.brand,
          price: addedItem.price_with_discount,
          items: preparedItems,
          quantity: addedItem.quantity,
          currency: addedItem.currency,
        });

        change_counter(data.cart_items_quantity, null, ".header_counter");
        // change_counter(data.cart_total_price, null, ".header_price");
        basket_render();
        document.querySelector(".basket_modal_wrapper").classList.add("active");
        document.querySelector(".overlay").classList.add("active");
      });
  } else if (isAddFavourBtn) {
    let item_id = target.closest(".product_card__block").dataset
      .quantity_item_id;

    if (isCardNotFavour) {
      console.log("item_id: ", item_id);
      var arr_api = {
        item_id: Number(item_id),
      };

      document.querySelector(".header-likes_counter").style.transform =
        "scale(1.3)";
      setTimeout(() => {
        document.querySelector(".header-likes_counter").style.transform =
          "scale(1)";
      }, 200);

      fetch(`/api/favour_items/`, {
        method: "POST",
        body: JSON.stringify(arr_api),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((data) => {
        if (data.ok) {
          const all_item_id = document.querySelectorAll(
            `[data-quantity_item_id="${item_id}"]`
          );
          const header_likes_counter = document.querySelector(
            ".header-likes_counter"
          );
          all_item_id.forEach((item) =>
            item.querySelector(".card_like").classList.add("liked")
          );
          header_likes_counter.textContent =
            +header_likes_counter.textContent + 1;
        }
      });
    } else if (isCardFavour) {
      fetch(`/api/favour_item/${item_id}/remove_by_like/`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((data) => {
        if (data.ok) {
          const header_likes_counter = document.querySelector(
            ".header-likes_counter"
          );

          const all_item_id = document.querySelectorAll(
            `[data-quantity_item_id="${item_id}"]`
          );
          all_item_id.forEach((item) =>
            item.querySelector(".card_like").classList.remove("liked")
          );

          header_likes_counter.textContent =
            +header_likes_counter.textContent - 1;
        }
      });
    }
  }
});

function change_quantity(item_id, value, wrapper) {
  let check_item = wrapper.classList.contains("item__block");
  if (!check_item) {
    fetch(`/api/cart_item/${item_id}/`, {
      method: "PATCH",
      body: JSON.stringify({
        quantity: value,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        change_counter(data.cart_items_quantity, null, ".header_counter");
        if (document.querySelector(".product_card_total_price") != undefined) {
          const cart_item_total_price = Number.isInteger(
            +data.cart_item_total_price
          )
            ? data.cart_item_total_price
            : data.cart_item_total_price.toFixed(2);
          wrapper.querySelector(
            ".product_card__block_all_value"
          ).textContent = `${cart_item_total_price}`;
          // let all_price = return_all_price();
          const cart_total_price = Number.isInteger(
            +data.cart_total_price_with_discount
          )
            ? data.cart_total_price_with_discount
            : data.cart_total_price_with_discount.toFixed(2);
          document
            .querySelectorAll(".product_card_total_price")
            .forEach((total) => (total.textContent = `${cart_total_price}`));
        }
        // $('.basket_all_sale_payment').text(`${data.cart_total_price} ${data.cart_currency}`);
      });
  }
}

function return_all_price() {
  let all_item = document.querySelectorAll(".product_card__block_all_value");
  let all_price = 0;
  all_item.forEach((element) => {
    let price = Number(element.dataset.price);
    all_price += price;
  });

  return all_price;
}

let item_content_info_btn_fast = document.querySelector(
  ".item_content_info_btn_fast"
);
let card_fast_buy = document.querySelectorAll(".card_fast_buy");
if (item_content_info_btn_fast != null) {
  item_content_info_btn_fast.addEventListener("click", function () {
    let name = this.dataset.card_name;
    let container = document.querySelector(".modal__block_fast_buy");
    let name_section = container.querySelector(".modal_name");
    let item_id = document
      .querySelector(".item_content_info__block")
      .closest(".product_card__block").dataset.quantity_item_id;
    let item_id_input = document.querySelector(".modal_form_fast_buy_hidden");
    item_id_input.value = item_id;
    name_section.textContent = name;
  });
}

if (card_fast_buy != null) {
  card_fast_buy.forEach((element) => {
    let wrapper = element.closest(".card_item");
    let name_product = wrapper.querySelector(".card_title").textContent;
    let item_id = wrapper.dataset.quantity_item_id;
    let item_id_input = document.querySelector(".modal_form_fast_buy_hidden");

    element.addEventListener("click", function (e) {
      let container = document.querySelector(".modal__block_fast_buy");
      let name_section = container.querySelector(".modal_name");
      console.log("name_section: ", name_section);
      name_section.textContent = name_product;
      item_id_input.value = item_id;
    });
  });
}

if (document.documentElement.offsetWidth <= 992) {
  document.body.addEventListener("click", (e) => {
    const target = e.target;
    const isCardItem =
      target.classList.contains("card_item") || target.closest(".card_item");
    const isActive =
      !target.classList.contains("active") &&
      !target.closest(".card_item").classList.contains("active");
    if (isCardItem && isActive) {
      document
        .querySelectorAll(".card_item")
        .forEach((card_item) => card_item.classList.remove("active"));
      e.preventDefault();
      target.classList.contains("card_item")
        ? target.classList.add("active")
        : target.closest(".card_item").classList.add("active");
    }
  });
}

function delete_btn() {
  let wrapper = this.closest(".product_card__block");
  let item_id = Number(wrapper.dataset.quantity_item_id);

  fetch(`/api/cart_item/${item_id}`, {
    method: "DELETE",
  })
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      delete_item(this, ".product_card__block");
      change_counter(data.cart_items_quantity, null, ".header_counter");
      // change_counter(data.cart_total_price, null, ".header_price");

      const preparedItems = data.cart_items.map(({ id, item, quantity }) => {
        const { title, brand, price } = item;
  
        return {
          id: id,
          name: title,
          brand: brand,
          price: price,
          quantity: quantity,
        };
      });

      const removedItem = {
        id: +wrapper.dataset.id,
        name: wrapper.querySelector(".basket_item_title span").textContent,
        price: wrapper.querySelector(".product_card__block_all_value").dataset
          .price,
        brand: wrapper.dataset.brand,
        quantity: wrapper.querySelector(".count__block input").value,
        currency: data.cart_currency,
        items: preparedItems,
      };

      removeFromCartGT(removedItem);

      document
        .querySelectorAll(".product_card_total_price")
        .forEach(
          (total) => (total.textContent = parseInt(data.cart_total_price))
        );

      // let length_item = document.querySelectorAll(".product_card__block_all_value").length;
      // let current_price = Number(
      //     wrapper.querySelector(".product_card__block_all_value").textContent,
      // );
      // let all_price;
      // if (length_item == 1) {
      //     all_price = 0;
      // } else {
      //     all_price = return_all_price() - current_price;
      // }

      // document.querySelector(".product_card_total_price").textContent = `${parseInt(
      //     all_price,
      //   )}`;

      if (!data?.cart_items?.length && window.location.href.includes("order")) {
        window.location.href = "/order";
      }
    });
}

function basket_plus() {
  let value = plus(".product_card__block", ".count_input", this);
  let wrapper = this.closest(".product_card__block");
  let item_id = Number(wrapper.dataset.quantity_item_id);
  change_quantity(item_id, value, wrapper);
}

function basket_minus() {
  let value = minus(".product_card__block", ".count_input", this);
  let wrapper = this.closest(".product_card__block");
  let item_id = Number(wrapper.dataset.quantity_item_id);
  change_quantity(item_id, value, wrapper);
}

function basket_input() {
  let value = input_basket(this);
  let wrapper = this.closest(".product_card__block");
  let item_id = Number(wrapper.dataset.quantity_item_id);
  change_quantity(item_id, value, wrapper);
}

function basket_render() {
  fetch("/api/cart_items/")
    .then((data) => {
      return data.json();
    })
    .then((data) => {
      const container = document.querySelector(".basket_product_container");
      container.textContent = "";

      data.cart_items.map((item, index) => {
        console.log("itedadsadm: ", item);
        const props = {
          image: item.item.images[0].image,
          price: item.price_with_discount,
          name: item.item.title,
          quantity: item.quantity,
          quantity_id: item.id,
          id: item.item.id,
          currency: item.currency,
          total_price: +item.price_with_discount * +item.quantity,
          amount: item.item.amount,
          brand: item.item.brand,
        };
        console.log("props: ", props);
        container.prepend(create_new_cart(props));

        let basket_cart = container.querySelector(".product_card__block");

        basket_cart
          .querySelector(".basket_item_delete")
          .addEventListener("click", delete_btn);
        basket_cart
          .querySelector(".count_minus")
          .addEventListener("click", basket_minus);
        basket_cart
          .querySelector(".count_input")
          .addEventListener("change", basket_input);
        basket_cart
          .querySelector(".count_plus")
          .addEventListener("click", basket_plus);
      });

      const total_currency = document.querySelector(
        ".total_sum__value-currency"
      );
      const cart_currency = data.cart_currency;
      // total_currency.textContent = cart_currency
      total_currency.textContent = "грн";

      const cart_total_price = Number.isInteger(
        +data.cart_total_price_with_discount
      )
        ? data.cart_total_price_with_discount
        : data.cart_total_price_with_discount.toFixed(2);
      const total_sum = document.querySelector(".total_sum__value-number");
      total_sum.textContent = cart_total_price;

      if (window.location.href.includes("order")) {
        const cartItemsGT = data.cart_items.map((item) => ({
          id: item.item.id,
          name: item.item.title,
          brand: item.item.brand,
          price: item.price_with_discount,
          quantity: item.quantity,
          currency: item.currency,
        }));

        console.log(data);

        beginCheckoutGT({
          totalPrice: cart_total_price,
          currency: cart_currency,
          items: cartItemsGT,
        });
      }

      if (!data?.cart_items?.length && window.location.href.includes("order")) {
        window.location.href.includes("order");
      }
    });
}

function create_new_cart({ ...props }) {
  let {
    name,
    id,
    quantity_id,
    price,
    quantity,
    image,
    currency,
    total_price,
    amount,
    brand,
  } = props;

  const totalPriceEdit = Number.isInteger(+total_price)
    ? total_price
    : total_price.toFixed(2);
  quantity = +quantity > +amount ? amount : quantity;

  let item = document.createElement("div");
  item.dataset.quantity_item_id = quantity_id;
  item.dataset.id = id;
  item.dataset.brand = brand;

  item.classList.add("basket_item", "product_card__block");

  item.innerHTML = `
    <div class="basket_item_img">
            <img src="${image}" alt="" />
    </div>
    <div class="basket_item__right">
        <div class="basket_item_top">
            <div class="basket_item_title">
                <span class="middle_title middle_title_1 color_black">${name}</span>
            </div>
            <div class="basket_item_delete">
                <div class="basket_item_delete_text sub_title sub_title_1 color_gray">
                    Видалити
                </div>
            </div>
        </div>

        <div class="basket_item_middle">
            <div class="count__block">
                <div class="count_minus"></div>
                <input data-item_amount="${amount}" type="number" value="${quantity}"
                    class="count_input bold_title bold_title_1 color_black" />
                <div class="count_plus"></div>
            </div>
            <div class="basket_item_price_all standart_title standart_title_3 color_black">
                <span class="product_card__block_all_value" data-price="${totalPriceEdit}">${totalPriceEdit}</span>
                <span class="basket_currency">грн</span> 
            </div>
        </div>
    </div>
`;

  return item;
}
