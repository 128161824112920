export const add_comment = (props) => {
    let comment = document.createElement('div');
    comment.innerHTML = `
    <div class="comment_item" value="${props.id}" name='review_id'>
        <div class="comment_info">
            <div class="comment_name bold_title bold_title_2 color_black">
                ${props.name}
            </div>
            <div class="comment_date regular_title regular_title_1 color_black">
                ${props.created}
            </div>
            <div class="comment_rate">
                <div class="comment_star">
                    <form class="form_stars blocked">
                        <span class="stars">
                            <input type="radio" name="star" class="star-1" data-star="1">
                            <label class="star-1" for="star-1">1</label>

                            <input type="radio" name="star" class="star-2" data-star="2">
                            <label class="star-2" for="star-2">2</label>

                            <input type="radio" name="star" class="star-3" data-star="3">
                            <label class="star-3" for="star-3">3</label>

                            <input type="radio" name="star" class="star-4" data-star="4">
                            <label class="star-4" for="star-4">4</label>

                            <input type="radio" name="star" class="star-5" data-star="5">
                            <label class="star-5" for="star-5">5</label>

                            <span></span>
                        </span>
                    </form>
                </div>
            </div>
        </div>
        <div class="comment_content regular_title regular_title_1 color_black">
            ${props.text}
        </div>
    </div>
    `
    return comment
}