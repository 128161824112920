import "./index.scss";
import validation from "../../module/validation/index";
import modal from "../../module/modal_script/index";
import { add_comment } from "./add_comment";
import { purchaseGT } from "../../module/googleTagEvents";

form_send(".contact_form", false);
form_send(".modal_form_fast_buy", false);
form_send(".subscribe_item", false);
form_send(".modal_form_comment", false);
form_send(".modal_form_login", false);
form_send(".modal_form_registery", false);
form_send(".order__block", false);
form_send(".profile_user__block", false);
form_send(".profile_password__block", false);
form_send(".profile_subscribe__block", true);

function form_send(wrapper, modal) {
  let form_wrapper = document.querySelectorAll(wrapper);
  let loader = document.querySelector(".modal_loading__block");

  form_wrapper.forEach((element) => {
    let action = element.getAttribute("action");

    let btn = element.querySelector(".validation_btn");
    if (btn != null) {
      btn.addEventListener("click", function (e) {
        e.preventDefault();
        let status = validation(btn);

        if (status == true) {
          console.log("send!");
          let elements = element.elements;
          let obj = {};

          for (let i = 0; i < elements.length; i++) {
            let item = elements.item(i);
            let check_type = ["file", "reset", "submit", "button"].indexOf(
              item.type
            );
            if (check_type > -1) {
            } else {
              obj[item.name] = item.value;
            }
          }
          if (wrapper == ".order__block") {
            console.log(11);
            let city = document.querySelector(".select_city").value;
            let department = document.querySelector(".select_department").value;
            console.log("department: ", department);
            let method = document.querySelector(".select_method").value;
            let payment_opt = document.querySelector(".select_payment").value;
            let adress = document.querySelector(".select_department").value;
            let current_address;
            let error__block = document.querySelector(".order_info_error");

            if (method == "courier") {
              current_address = `місто: ${
                document.querySelector(".courier_city").value
              } - адреса: ${document.querySelector(".courier_adress").value}`;
            } else if (method == "nova_pochta") {
              current_address = `місто: ${city} - відділення: ${department}`;
            } else {
              current_address = `Самовивіз з ${
                document.querySelector(".self_pickup_shop").value
              }`;
            }
            obj["address"] = current_address;
            obj["delivery_opt"] = method;
            obj["payment_opt"] = payment_opt;

            if (department == "" && method == "nova_pochta") {
              error__block.textContent = "Оберіть відділення!";
            } else if (action != "") {
              loader.classList.add("active");
              fetch(action, {
                method: "POST",
                body: new URLSearchParams(obj),
                // body: new URLSearchParams(obj).toString(),
              })
                .then((data) => {
                  return data.json();
                })
                .then((data) => {
                  console.log("data: ", data)

                  if (data.message && obj.payment_opt === 'cash') {
                    return bad_modal(data.message);
                  } else {
                    modal_done(data, wrapper);
                  }
                })
                .catch((error) => {
                  console.log("error: ", error.message);

                  bad_modal(error.message);
                });

              error__block.textContent = "";
            }
            // else {
            //     error__block.textContent = "Оберіть відділення!";
            // }
          } else {
            console.log(22);

            if (action != "" || action != undefined) {
              loader.classList.add("active");
              fetch(action, {
                method: "POST",
                body: new URLSearchParams(obj),
                // body: new URLSearchParams(obj).toString(),
              })
                .then((data) => {
                  return data.json();
                })
                .then((data) => {
                  console.log("data: ", data);
                  modal_done(data, wrapper);
                })
                .catch((error) => {
                  console.log("error: ", error);
                  bad_modal();
                });
            }
          }
        } else {
          console.log("error!");
        }
      });
    } else {
      console.error(
        `такого модального вікна не існує на цій сторінці - ${wrapper}`
      );
    }
  });
}

function modal_done(data, wrapper) {
  console.log("data111111: ", data);
  let url = data.url;
  let modal__block = document.querySelector(wrapper);
  let loader = document.querySelector(".modal_loading__block");

  if (
    data?.status === "OK" ||
    data?.url.includes("thank_you") ||
    data?.url.includes("payment")
  ) {
    if (wrapper.includes("order__block")) {
      purchaseGT({
        transaction_id: data?.order_id,
        totalPrice: data?.order_price,
        currency: data?.order_currency,
        items: data?.ordered_items,
      });
    }

    if (wrapper == ".modal_form_comment") {
      const stars = Math.round(+data.stars);
      const reviews_count = data.reviews_count;
      document.querySelector(`[data-star="${stars}"]`).checked = true;
      document.querySelector(
        ".item_content_additional_num"
      ).textContent = `(${reviews_count})`;

      document
        .querySelector(".comment__block")
        .append(add_comment(data.review));
      const last_comment = document.querySelector(
        `.comment__block [value="${data.review.id}"]`
      );
      last_comment.querySelector(
        `[data-star="${data.review.rating}"]`
      ).checked = true;

      document
        .querySelector(wrapper)
        .querySelectorAll(".input_basic")
        .forEach((input) => (input.value = ""));
      document
        .querySelector(wrapper)
        .querySelector(".comment-star-5").checked = true;
      document
        .querySelector(".modal__block_comment")
        .classList.remove("active");
      document.querySelector(".overlay").classList.remove("active");
    }
    accept_modal();
    remove_error();
  } else {
    let all_error = data.error_fields;
    loader.classList.remove("active");
    //remove_error();
    if (data.message) {
      bad_modal(data.message);
    } else {
      Object.values(all_error).forEach((error, index) => {
        bad_modal(error, wrapper);
        // let field_error = document.createElement("div");
        // field_error.textContent = error;
        // field_error.classList.add("field_error", "sub_title", "sub_title_1", "color_red");
        // modal__block.append(field_error);
      });
    }
  }

  if (url != undefined) {
    window.location.href = url;
  }
}

export function bad_modal(error_message, wrapper) {
  //debugger
  remove_error();

  const timeToAppear = error_message ? 0 : 500;

  let loader = document.querySelector(".modal_loading__block");
  let bad = document.querySelector(".modal_bad__block");
  const modalBadText = document.querySelector(".modal_bad_text");
  const modalBadSVG = document.querySelector(".modal_bad_svg");

  modalBadText.hidden = false;
  modalBadSVG.hidden = false;

  if (error_message) {
    modalBadText.hidden = true;
    modalBadSVG.hidden = true;

    console.log("error_message: ", error_message);
    let field_error = document.createElement("div");
    field_error.textContent = error_message;
    field_error.classList.add(
      "custom_modal_text",
      "modal_bad_text",
      "bold_title",
      "bold_title_2",
      "color_red"
    );
    console.log("field_error: ", field_error);
    bad.append(field_error);

    document.body.classList.remove("prevent_scrolling");
  }

  setTimeout(() => {
    loader.classList.remove("active");
    bad.classList.add("active");
  }, timeToAppear);
  setTimeout(() => {
    bad.classList.remove("active");
  }, 3000);
}

function accept_modal() {
  let loader = document.querySelector(".modal_loading__block");
  let accept = document.querySelector(".modal_accept__block");

  setTimeout(() => {
    loader.classList.remove("active");
    accept.classList.add("active");
  }, 500);
  setTimeout(() => {
    accept.classList.remove("active");
  }, 3000);
}

function remove_error() {
  let errors = document.querySelectorAll(".field_error");
  errors.forEach((error) => {
    error.remove();
  });

  let errors_modal = document.querySelectorAll(".custom_modal_text");
  errors_modal.forEach((error) => {
    error.remove();
  });
}
